<template>
<div class="px-4 sm:px-6 lg:px-4 xl:px-6 pt-4 pb-4 sm:pb-6 lg:pb-4 xl:pb-6 space-y-4 h-full">


    <div class="flex h-full w-full" :class="isLoading ? 'hidden' : 'block'">
    
        <div class="flex sm:flex-row w-full flex-col h-full">

            <div class="sm:w-64">
                <div class="mb-2">
                    <p class="font-semibold text-sm text-gray-800 text-left ml-2">Abonnements</p>
                </div>
        
                <div class="rounded-lg">
                  <!-- <el-scrollbar height="413px" class="w-full border rounded-md bg-white"> -->
                    <el-menu
                        class="el-menu-vertical-demo w-full border rounded-lg text-center bg-white"
                        @select="handleSelect"
                        @open="handleOpen"
                        @close="handleClose"
                        :collapse="isCollapse"
                    >
                      <el-scrollbar height="413px" class="w-full rounded-lg bg-white">
                        <el-menu-item  v-for="year in years" :key="year.id" v-bind:index="year.id">
                          <template #title>{{ year.title  }} </template>
                        </el-menu-item>
                      </el-scrollbar>
                    </el-menu>
                  <!-- </el-scrollbar> -->
                </div>

            </div>


            <div class="flex flex-col w-full sm:ml-6 sm:mt-0  ">
                <div class="inline-flex justify-between items-center">
                    <div class="mb-2">
                      <p class="font-semibold text-sm text-gray-800 text-left ml-2 sm:mt-0 mt-5">Formulaire descriptif</p>
                    </div>
                    <div></div>
                </div>


                <div class="border rounded-lg py-3 h-full">



                  <div :class="isLoadingDescription ? 'block' : 'hidden'" class="flex items-center justify-center sm:mt-32 w-full">
                    <!--<div class="fulfilling-square-spinner">
                      <div class="spinner-inner"></div>
                    </div>-->
                    <div class="loading">
                      <div class="dot"></div>
                      <div class="dot"></div>
                      <div class="dot"></div>
                      <div class="dot"></div>
                    </div>
                  </div>

                  <div :class="isLoadingDescription ? 'hidden' : 'block'">
                        <div v-if="selectedYear == null" class="flex justify-center items-center h-full p-5 sm:mt-12">
                            <el-result 
                              icon="info"
                              title="Aucun abonnement sélectionné"
                              subTitle="Veuillez sélectionner un abonnement"
                            >
                            </el-result>
                        </div>
                        <div v-else>
                            
                            <div class="mx-4 mb-3 mt-1">

                                <div class="flex sm:flex-row flex-col items-center ">

                                  <div class="flex flex-col w-full sm:mx-2 sm:mt-0 ">
                                      <el-select @change="handleSelectModule()" v-model="subcriptionModule" placeholder="Module" class="w-full">
                                        <el-option v-for="item in modules" :key="item.value" :label="item.label" :value="item.value">
                                        </el-option>
                                      </el-select>
                                  </div>
                                </div>
                                
                                <div class="flex sm:flex-row flex-col items-center mt-4">
                                
                                  <div class="flex flex-col w-full sm:mx-2 sm:mt-0 mt-3">
                                    <div class="mb-1">
                                      <p class="font-semibold text-sm text-gray-800 text-left ml-2">Titre</p>
                                    </div>
                                    <el-input placeholder="Titre" v-model="title" />
                                  </div>
                                </div>

                                <div class="flex sm:flex-row flex-col items-center mt-4">
                                  <div class="flex flex-col sm:w-1/2 w-full sm:mx-2 sm:mt-0 mt-3">
                                      <div class="mb-1">
                                          <p class="font-semibold text-sm text-gray-800 text-left ml-2">Ancien prix</p>
                                      </div>
                                      <el-input placeholder="Ancien prix" v-model="oldprice" />
                                  </div>
                                  <div class="flex flex-col sm:w-1/2 w-full sm:mx-2 sm:mt-0 mt-3">
                                      <div class="mb-1">
                                          <p class="font-semibold text-sm text-gray-800 text-left ml-2">Nouveau prix</p>
                                      </div>
                                      <el-input placeholder="Nouveau prix" v-model="newprice" />
                                  </div>
                                </div>


                                 <div class="flex sm:flex-row flex-col items-center mt-4">
                                  <div class="flex flex-col w-full sm:mx-2 sm:mt-0 mt-3">
                                      <div class="mb-1">
                                          <p class="font-semibold text-sm text-gray-800 text-left ml-2">Descriptif</p>
                                      </div>
                                      <QuillEditor theme="snow" toolbar="full" contentType="html" ref="myQuillEditor" v-model:content="content" />
                                  </div>
                                </div>
                                


                                <div class="flex justify-center items-center mt-4">
                                  <button @click="update" class="py-2 px-4 flex items-center  text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">Modifier</button>
                                </div>
                                

                            </div>
                        </div>
                  </div>

                </div>

            </div>
        </div>
    </div>

    <div
      :class="isLoading ? 'block' : 'hidden'" class="flex items-center justify-center h-full w-full">
      <!--<div class="fulfilling-square-spinner">
        <div class="spinner-inner"></div>
      </div>-->
      <div class="loading">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>

</div>

</template>

<script>
import axiosIns from '../../plugins/axios';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import Quill from 'quill'
Quill.register('@vueup/vue-quil', QuillEditor)

import { ElMessage } from 'element-plus'
import { h } from 'vue'

export default {
    components: {
      QuillEditor, 
    },
    data () {
        return {
            selectedId : null,
            title : null,
            oldprice: null,
            newprice:null,
            content: null,
            isLoading : false,
            isLoadingDescription: false,
            selectedYear: null,
            modules: [],
            subcriptionModule: null,
            years:[
            {
                id: 1,
                title: 'Première Année',
            },
            {
                id: 2,
                title: 'Deuxième Année',
            },
            {
                id: 3,
                title: 'Troisième Année',
            },
            {
                id: 4,
                title: 'Quatrième Année',
            },
            {
                id: 5,
                title: 'Cinquième Année',
            },
            {
                id: 6,
                title: 'Sixième Année',
            },
            {
                id: 0,
                title: 'Résidanat',
            },
            ]
        }
    },

    mounted () {
        
    },

    methods: {
      loadModuleOfYear() {
        axiosIns.get("/moduleofyear/" + this.selectedYear)
          .then(({ data }) => {
            var mod = data.modules;

            let temps = []
            if (data.modules.length != undefined) {
              data.modules.forEach(element => {

                const item = {
                  label: element.title,
                  value: element.id
                }
                temps.push(item);
              });
            } else {
              const item = {
                label: mod[Object.keys(mod)[0]].title,
                value: mod[Object.keys(mod)[0]].id
              }
              temps.push(item);
            }
            const item = {
              label: 'Aucun module sélectionné (Abonnement annuel de l\'année '+ this.selectedYear+')',
              value: null
            }
            temps.push(item);
            this.modules = temps;
          })
          .catch(() => {
            
          });
      },
      handleSelect(key) {
        this.selectedYear = key;
        this.subcriptionModule = null;
        this.loadModuleOfYear();
        this.isLoadingDescription = true;
        if(this.selectedYear == '0' ){
          this.selectedYear = '7'
        }
        axiosIns.get("/subscribeinfo/"+this.selectedYear)
          .then(({ data }) => {
             this.selectedId = data.result.id;
             this.title = data.result.title;
             this.oldprice = data.result.old_price;
             this.newprice = data.result.price;
             this.content = data.result.description;
             document.querySelector(".ql-editor").innerHTML = data.result.description;
             this.isLoadingDescription = false;
          })
          .catch(() => {
            this.isLoadingDescription = false;
          });


        },
        handleSelectModule(){
          if (this.subcriptionModule == null){
            this.handleSelect(this.selectedYear);
          }else{
            this.isLoadingDescription = true;
            axiosIns.get("/subscribeinfomodule/" + this.subcriptionModule)
              .then(({ data }) => {
                this.selectedId = data.result.id;
                this.title = data.result.title;
                this.oldprice = data.result.old_price;
                this.newprice = data.result.price;
                this.content = data.result.description;
                document.querySelector(".ql-editor").innerHTML = data.result.description;
                this.isLoadingDescription = false;
              })
              .catch(() => {
                this.isLoadingDescription = false;
              });
          }
        },
        update(){
          this.isLoadingDescription = true;
          axiosIns.put("/subscribeinfo/"+this.selectedId,{
                  title : this.title,
                  old_price : this.oldprice,
                  price : this.newprice,
                  description : this.content
                })
                .then(() => {
                  this.isLoadingDescription = false;
                  console.log(this.selectedId);
                  this.success()
                })
                .catch(() => {
                  this.isLoadingDescription = false;
                  console.log(this.selectedId);
                  this.errorm()
                });

        },

        
    },
    computed: {
        isToken() {
            return this.$store.getters.get_token;
        },
        User() {
          return this.$store.getters.get_user;
        },
    },

    setup() {
      const success = () => {
        ElMessage({
          message: h('p', { style: 'color: #65a30d' }, [
            h('span', null , 'Les informations de l\'abonnement ont été modifiées avec succès'),
          ]),
          type: 'success',
        })
      }
      const errorm = () => {
          ElMessage.error('Une erreur est survenue !')
      }
       return {
         success,
         errorm
        }
    }


}

</script>
<style>
.ql-editor{
  height: 300px;
}
</style>